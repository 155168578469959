html {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;

  /* Global Colors */
  --halal-word-color: #0089FF;
  --increasing-color: #03E08C;
  --decreasing-color: #FF2727;
  --border-color: #f2f2f2;
  --heading-color: #828282;
  --item-hover-color: #f8fafd;
  font-weight: 500;
  scroll-behavior: smooth;
}

body {
  padding: 20px;
}

a {
  text-decoration: none;
  color: black;
}

tbody tr:hover {
  background-color: var(--item-hover-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
