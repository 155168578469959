.trustScore {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 auto;
}

.image {
    width: 32px;
    vertical-align: middle;
    margin-right: 12px;
}

.mainInfoLayout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 32px;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 40px 0;
}

.coinRank {
  display: inline-block;
  padding: 6px 8px;
  font-weight: 500;
  background-color: #EBF3FF;
  color: var(--halal-word-color);
}

.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  line-height: 44px;
}
.mainInfoContainer > img {
  height: 42px;
}
.mainInfoContainer > .coinName {
  font-size: 36px;
}

.mainInfoContainer > .coinSymbol,
.mainInfoContainer > .buyButton {
  line-height: normal;
  border-radius: 4px;
  padding: 6px 8px;
  font-weight: 500;
}

.mainInfoContainer > .coinSymbol {
  background-color: var(--border-color);
  color: var(--heading-color);
}
.mainInfoContainer > .buyButton {
  background-color: var(--halal-word-color);
  color: white;
}

/* ------------ */
.indicators {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1.3fr 1fr;
  grid-gap: 20px 20px;
  font-size: smaller;
  margin-bottom: 40px;
}
.indicators > * {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.indicators > *:first-child {
  grid-row: 1/3;
}
.indicators > *:first-child > .indicatorValue {
  font-size: 1.6em;
}
.indicatorTitle {
  color: var(--heading-color);
}
.indicatorValue {
  font-size: larger;
  font-weight: 600;
}
.coinTitle {
  font-size: large;
}

/* ------------- */
.progressBarContainer {
  background-color: #C4C4C4;
  border-radius: 10px;
  height: 6px;
}
.progressBarContainer > * {
  background-color: var(--increasing-color);
  height: 100%;
}

.chatContainer {
  max-width: 700px;
  margin: 0 auto;
}
