body {
  font-family: Inter;
}

.ticker {
  color: #bdbdbd;
  font-weight: 600;
}
.currencyImage {
  width: 32px;
  vertical-align: middle;
  margin-right: 12px;
}
.sparkLine7DaysCanvas {
  width: 150px;
  height: 50px;
}

.loading {
  filter: blur(3px);
  /* background-image: url(https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif); */
  background-position: center;
  background-repeat: no-repeat;
}

.sortUp:after,.sortDown:after {
  font-size: 0.7rem;
  margin-left: 4px;
}
.sortUp:after { content: ' ▲'; }
.sortDown:after { content: ' ▼'; }

.haramRisk {
  border-radius: 4px;
  padding: 9px 16px;
  margin: 0;
  text-align: center;
  font-family: Inter;
}

.link {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.linkRestText {
  flex-direction: column;
}
.linkRestText > span {
  font-size: .9rem;
}

@media screen and (max-width: 1000px) {
  .linkRestText {
    display: flex;
  }
}

.rank {
  width: 36px;
  text-align: left;
}
.squeezedRank {
  display: none;
  background-color: var(--border-color);
  color: var(--heading-color);
  padding: 0 4px;
  border-radius: 2px;
}

.haramRiskCloser { display: none; }

@media screen and (max-width: 900px) {
  .squeezedRank {
    display: initial;
  }
  .rank {
    display: none;
  }
  .link {
    width: 155px;
  }
  .haramRiskCloser { display: table-cell; }
  .haramRiskFarther { display: none; }
}
