.parent {
  font-family: Inter;
}

.bigSize {
  font-size: calc(1.3rem + 2vmin);
  font-weight: bold;
  line-height: 50px;
}

.mediumSize {
  font-size: calc(2px + .4rem + 2vmin);
  font-weight: bold;
  line-height: 30px;
  padding-top: 20px;
}

.smallSize {
  font-size: calc(1rem + .2vmin);
  margin-bottom: 40px;
}

@media screen and (max-width: 420px) {
  .bigSize {
    font-size: calc(7.2vw - 1px);
  }
  .mediumSize {
    font-size: calc(5vw - 1.1px);
  }
}
