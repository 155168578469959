.root {
  box-shadow: 0px 1px 2px rgb(0 0 0 / 8%);
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 300px;
}


.inputContainer {
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
}
.inputContainer > img {
  margin: 0 8px;
  width: 16px;
}
.input {
  border: 0;
  outline: 0;
  height: 100%;
  font-size: 1.2rem;
}
.input::placeholder {
  color: #B4B4B4;
}

.foundList {
  position: absolute;
  top: 75px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 12%);
  line-height: 2.5;
  max-height: 300px;
  overflow: auto;
  background-color: white;
  padding-left: 10px;
  width: 290px;
  font-size: 1.1rem;
}

.foundList > * {
  margin: 10px 0 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.foundList mark {
  background-color: inherit;
  color: inherit;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
}

.foundList a {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.ticker {
  color: #bdbdbd;
  font-size: small;
}

@media screen and (max-width: 800px) {
  .root {
    position: absolute;
    top: 60px;
    left: 0;
    margin: 10px;
    width: calc(100% - 20px);
  }
  .foundList {
    width: calc(100% - 12px);
    top: 41px;
    box-shadow: 0px 3px 2px 2px rgb(0 0 0 / 12%);
  }
}
