.tableContainer {
  overflow: auto;
  margin-bottom: 30px;
}

.table {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
  border-collapse: collapse;
}

.table > thead {
  color: var(--heading-color);
  height: 80px;
}

.table td {
  padding: 20px 0;
}

.table > tbody tr {
  border-top: 1px solid var(--border-color);
}

/* Colors: */
.green,.low {background-color: var(--increasing-color); color: white;}
.medium {background-color: var(--halal-word-color); color: white;}
.red,.high {background-color: var(--decreasing-color); color: white;}
.unverified {background-color: #ECF0F1; color: black;}
.yellow {background-color: yellow;}


.increasingColor {color: var(--increasing-color);}
.decreasingColor {color: var(--decreasing-color);}
.halalWord {color: var(--halal-word-color);}
