header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(0.5rem + 1vmin);
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 32px;
}

@media screen and (max-width: 800px) {
  header {
    margin-bottom: 50px;
  }
}

.title {
  font-weight: bold;
  font-size: calc(0.7rem + 1.4vmin);
}

.menuItems {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 12px;
}

.menuItems > a {
  font-family: Inter;
  font-weight: 500;
  padding: 15px 0;
  margin: 3px;
}
