.descriptionContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  font-weight: 400;
  font-size: 18px;
  line-height: 1.8;
  text-align: justify;
}

.internalDescription {
  white-space: pre-line;
}
.internalDescription > a {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}
.internalDescription > a:visited {
  color: rgb(85, 26, 139);
}
