.footer > * {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px 0;
  font-size: 1rem;
}

.link {
  padding: 4px 10px;
  margin: 0 2px;
}
.link:hover {
  color: gray;
}
