.root {
  margin: 12px;
}
.showOnHover {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 999;
}
.bubble {
  margin-top: 16px;
  background: white;
  box-shadow:
    0px 8px 32px rgb(128 138 157 / 24%),
    0px 8px 32px rgb(128 138 157 / 24%);
}
.bubble > * {
  display: flex;
  flex-direction: column;
}
.pointer {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 20px;
  margin-top: 10px;
  z-index: -1;
  transform: rotate(45deg);
  box-shadow: 1px 1px 3px 0px;
}
.alwaysShow:hover + .showOnHover, .showOnHover:hover {
  display: flex;
}

.link > a, .button {
  background: var(--border-color);
  border-radius: 4px;
  padding: 6px 12px;
}
.link img {
  margin-left: 8px;
  height: 13px;
}

.dropDownItem {
  padding: 10px;
}
.dropDownItem > a {
  font-size: 12px;
  font-weight: 600;
}
.dropDownItem:hover {
  background-color: var(--item-hover-color);
}
.dropDownItem > img {
  margin-left: 4px;
}
